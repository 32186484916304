// 1. Install dependencies DONE
// 2. Import dependencies DONE
// 3. Setup webcam and canvas DONE
// 4. Define references to those DONE
// 5. Load posenet DONE
// 6. Detect function DONE
// 7. Drawing utilities from tensorflow DONE
// 8. Draw functions DONE

import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import CustomProgressBar from "./components/CustomProgressBar";
import styles from "./main.module.css";
import * as tf from "@tensorflow/tfjs";
// NEW MODEL
import * as facemesh from "@tensorflow-models/face-landmarks-detection";
import Webcam from "react-webcam";
import { drawMesh } from "./utilities";
import { Spinner } from "react-bootstrap";
import xaiLogo from './xai.png'
function App() {
  const [values, setValues] = useState({ faceInViewConfidence: 0,mesh:[] });
  const [netLoaded, setNetLoaded] = useState(false);
  const [ShowCam, setShowCam] = useState(true)
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  //  Load posenet
  const runFacemesh = async () => {
    // NEW MODEL
    const net = await facemesh.load(
      facemesh.SupportedPackages.mediapipeFacemesh,
      { maxFaces: 1 }
    );
    setInterval(() => {
      detect(net);
    }, 10);
  };

  const detect = async (net) => {
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections
      // OLD MODEL
      //       const face = await net.estimateFaces(video);
      // NEW MODEL
      const face = await net.estimateFaces({ input: video });
      // console.log(face);
      face.length > 0 && !netLoaded && setNetLoaded(true);
      setValues(face.length !== 0 ? face[0] : { faceInViewConfidence: 0 });

      // Get canvas context
      const ctx = canvasRef.current.getContext("2d");
      requestAnimationFrame(() => {
        drawMesh(face, ctx);
      });
    }
  };

  useEffect(() => {
    runFacemesh();
  }, []);

  return (
    <div className={styles.container}>
      <img
      alt="xai logo"
        src={xaiLogo}
        style={{
          height: 200,
          marginBottom: 50,
        }}
        onClick={()=>setShowCam(!ShowCam)}
      />
      <div className={styles.canvas}>
        <Webcam
          ref={webcamRef}
          className={!ShowCam && styles.hidden}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            width: 640,
            height: 480,
            background: "linear-gradient(315deg, #232323, #1e1e1e)",
            borderRadius: 16,
            // boxShadow: "20px 20px 60px #b8b8b8,-20px -20px 60px #ffffff",
          }}
        />

        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
            zindex: 9,
            width: 640,
            height: 480,
          }}
        />
      </div>
      {netLoaded ? (
        <div className={styles.stats}>
          <div className={styles.progressBar}>
          <div style={{textAlign:"center",margin:50}}>
          <h1>Points tracking: {values.mesh?values.mesh.length:0}</h1>
          </div>
          <div style={{marginTop:20}}>
            <CustomProgressBar value={values} />
          </div>
          </div>
        </div>
      ) : (
      <div className={styles.loader}>
         <div>
         <Spinner animation="border"style={{ color:"#5651E3"}} />
           </div>
        <div>
        <p>Loading XAM models...</p>
        </div>
        </div>
      )}
    </div>
  );
}

export default App;
