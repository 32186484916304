import React from 'react'
import { ProgressBar } from 'react-bootstrap'
const CustomProgressBar = (props) => {
  const { value } = props
//   console.log(value)
  let variantHandler = (value) => {
      let string=""
    switch (true) {
      case( value > 80 && value <= 100):
        string="success";
        break;
      
      case (value > 40 && value <= 80):
        string="warning";
        break;
       
      case (value >= 0 && value <= 40):
        string="danger";
        break;
        default:
            console.log('deff')
            break;
    }
    // console.log(string)
    return string
  }

  return (
    <div>
      <ProgressBar
        style={{ height: 20 }}
        variant={variantHandler(value.faceInViewConfidence * 100)}
        now={value.faceInViewConfidence * 100}
        label={`${(value.faceInViewConfidence*100).toFixed(2)}%`}
      />
    </div>
  )
}

export default CustomProgressBar
